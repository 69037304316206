import './App.css';
import { FilterableProductTable } from './FilterableProductTable';


const PRODUCTS = [
  {category: "dinner", ingredients: ["chorizo", "rice", "bell pepper"], name: "Chorizo rice"},
  {category: "dinner", ingredients: ["basil", "pine nuts", "pasta", "garlic"], name: "Pesto pasta"},
  {category: "dinner", ingredients: ["burger buns", "steak", "ketchup", "mayo"], name: "Burgers"},
];

function App() {
  return (
    <div className="App">
      <header className="h-64 w-full">
        <div class="bg-base-200 w-full absolute inset-y-0 h-64">
          <div class="bg-base-100 shadow-xl h-64 w-full">
              <h1 className="text-primary text-center text-3xl font-bold absolute inset-1/2">
                RECIPES
              </h1>
          </div>
        </div>
      </header>
      <body><FilterableProductTable products={PRODUCTS} /></body>
    </div>
  );
}

export default App;
